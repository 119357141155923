import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Storyblok from '../StoryblokService';
import Header from './Header';
import SectionTitle from './SectionTitle';
import SectionTitleText from './SectionTitleText';
import SectionSubtitle from './SectionSubtitle';
import Services from './Services';
import Safety from './Safety';
import SurveySection from './SurveySection';
import SurveyThankSection from './SurveyThankSection';
import Footer from './Footer';
import SocialMediaLinks from './SocialMediaLinks';
import CookieBar from './CookieBar';
import { useLocation } from 'react-router-dom';

function LandingPage({ language }) {
  const [data, setData] = useState(null);

  // `cookiesAccepted` aus sessionStorage initialisieren
  const [cookiesAccepted, setCookiesAccepted] = useState(() => {
    return sessionStorage.getItem('cookiesAccepted') === 'true';
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const diFull = queryParams.get('di');

  let di, options;

  if (diFull && diFull.length === 33) {
    di = diFull.slice(0, 32);
    options = parseInt(diFull.slice(-1), 10).toString();
  } else {
    di = diFull;
    options = null;
  }

  const showThankSection = queryParams.get('survey') === 'TRUE';
  const firstThreeDigits = di ? di.slice(0, 3) : '';

  useEffect(() => {
    const story = language === 'en' ? 'onboard-webapp-en' : 'onboard-webapp';
    Storyblok.get(`cdn/stories/${story}`, {
      version: 'draft'
    })
      .then(response => {
        setData(response.data.story.content);
      })
      .catch(error => {
        console.error(error);
      });
  }, [language]);

  if (!data) {
    return <div>Loading...</div>;
  }

  const {
    logo,
    sectionTitle,
    sectionTitleText,
    sectionSubtitle,
    services,
    safety,
    surveyIcon,
    surveyTitle,
    surveySubTitle,
    surveyText,
    surveyButtonText,
    surveyButtonLink,
    footerText,
    safetyTitle,
    serviceTitle,
    surveyThankText,
    socialMedia,
    cookieText,
    cookieButtonText,
    cookieDeclineText,
  } = data;

  const surveyLinkWithHashtag = di ? `${surveyButtonLink}&hashtag=${di}${options}` : surveyButtonLink;

  // Funktion für Zustimmung
  const handleCookieAccept = () => {
    sessionStorage.setItem('cookiesAccepted', 'true');
    sessionStorage.setItem('cookiesAcceptedTimestamp', new Date().getTime());
    setCookiesAccepted(true);
  };

  // Funktion für Ablehnung
  const handleCookieDecline = () => {
    sessionStorage.removeItem('cookiesAccepted');
    sessionStorage.removeItem('cookiesAcceptedTimestamp');
    setCookiesAccepted(false);
    window.location.reload();
  };

  console.log("CookiesAccepted (State):", cookiesAccepted);
  console.log("CookiesAccepted (SessionStorage):", sessionStorage.getItem('cookiesAccepted'));
  console.log("showThankSection:", showThankSection);

  return (
    <div className="App">
      <Helmet>
        <title>Blaguss Onboard</title>
      </Helmet>
      <Header logo={logo?.filename} />
      <main className="main-content">
        <SectionTitle title={sectionTitle} />
        <SectionSubtitle title={sectionSubtitle} />
        <SectionTitleText title={sectionTitleText} />
        <Services title={serviceTitle} items={services} options={options} />
        <Safety title={safetyTitle} items={safety} options={options} />

        {cookiesAccepted && (
          <>
            {showThankSection ? (
              <SurveyThankSection title={surveyTitle} text={surveyThankText} />
            ) : (
              <SurveySection
                icon={surveyIcon?.filename}
                title={surveyTitle}
                subtitle={surveySubTitle}
                text={surveyText}
                buttonText={surveyButtonText}
                buttonLink={surveyLinkWithHashtag}
              />
            )}
          </>
        )}

        {socialMedia && <SocialMediaLinks blok={socialMedia} />}
        <Footer
          text={footerText}
          hashcode={firstThreeDigits}
          options={options}
          lang={language}
          cookiesAccepted={cookiesAccepted}
          onDeclineCookies={handleCookieDecline}
          onAcceptCookies={handleCookieAccept}
          buttonTextD={cookieDeclineText}
          buttonTextE={cookieButtonText}
        />

        <CookieBar
          text={cookieText}
          buttonText={cookieButtonText}
          buttonTextD={cookieDeclineText}
          onAccept={handleCookieAccept}
          onDecline={handleCookieDecline}
        />
      </main>
    </div>
  );
}

export default LandingPage;
