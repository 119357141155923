import React, { useState, useEffect } from 'react';

const CookieBar = ({ text, buttonText, buttonTextD, onAccept, onDecline }) => {
  const [showCookieBar, setShowCookieBar] = useState(false);

  useEffect(() => {
    const acceptedTimestamp = sessionStorage.getItem('cookiesAcceptedTimestamp');
    const currentTime = new Date().getTime();

    // CookieBar anzeigen, wenn keine Zustimmung vorliegt
    if (!acceptedTimestamp || currentTime - acceptedTimestamp > 365 * 24 * 60 * 60 * 1000) {
      setShowCookieBar(true);
    }
  }, []);

  const handleAccept = () => {
    // Zustimmung in sessionStorage speichern
    sessionStorage.setItem('cookiesAccepted', 'true');
    sessionStorage.setItem('cookiesAcceptedTimestamp', new Date().getTime());
    setShowCookieBar(false);

    // Callback-Funktion aufrufen, falls definiert
    if (onAccept) {
      onAccept();
    }
  };

  const handleDecline = () => {
    // Zustimmung aus sessionStorage entfernen
    sessionStorage.removeItem('cookiesAccepted');
    sessionStorage.removeItem('cookiesAcceptedTimestamp');
    setShowCookieBar(false);

    // Callback-Funktion aufrufen, falls definiert
    if (onDecline) {
      onDecline();
    }
  };

  return (
    <>
      {showCookieBar && (
        <div className="cookie-bar-1">
          <p dangerouslySetInnerHTML={{ __html: text }} />
          <div className="cookie-bar-buttons">
            <button onClick={handleAccept}>{buttonText}</button>
            <button onClick={handleDecline}>{buttonTextD}</button>
          </div>
        </div>
      )}
    </>
  );
};

export const areCookiesAccepted = () => {
  const acceptedTimestamp = sessionStorage.getItem('cookiesAcceptedTimestamp');
  const currentTime = new Date().getTime();

  // Zustimmung ist gültig, wenn sie existiert und jünger als ein Jahr ist
  return (
    sessionStorage.getItem('cookiesAccepted') === 'true' &&
    acceptedTimestamp &&
    currentTime - acceptedTimestamp <= 365 * 24 * 60 * 60 * 1000
  );
};

export default CookieBar;
