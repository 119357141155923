import React, { useState } from 'react';

const Accordion = ({ title, items, showIcon = true, isList = false, options }) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={`accordion ${isActive ? 'active' : ''}`}>
      <div className={`accordion-item ${isActive ? 'active' : ''}`}>
        <div className="accordion-header" onClick={handleToggle}>
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        {isActive && (
          <div className="accordion-content">
       {isList ? (
  <>
    {items.length > 0 && (
      <div className="item-header">
        <h3 dangerouslySetInnerHTML={{ __html: items[0].title }} />
      </div>
    )}
    <ul>
      {items
        .filter(item => {
          // Filterlogik wie zuvor
          const markerOptions =
            item.marker === "" || item.marker == null ? null : Number(item.marker);

            console.log(
              "Filter Check - Title:",
              item.title,
              "Marker:",
              markerOptions,
              "Options:",
              options
            );

          // Wenn kein Marker gesetzt ist, immer anzeigen
          if (markerOptions === null) {
            return true;
          }

          // Anzeigelogik basierend auf Optionen
          return Number(markerOptions) === Number(options);
        })
        .slice(1) // Verwendet die gefilterte Liste
        .map((item, index) => (
          <li key={index}>{item.title}</li>
        ))}
    </ul>
  </>
) : (
              items
                .filter(item => {
                  // Logik zur Filterung basierend auf `options`
                  const markerOptions =
                    item.marker === "" || item.marker == null
                      ? null
                      : Number(item.marker);

                  // Wenn kein Marker gesetzt ist, immer anzeigen
                  if (markerOptions === null) {
                    return true;
                  }

                  // Anzeigelogik basierend auf Optionen
                  return Number(markerOptions) === Number(options);
                })
                .map((item, index) => (
                  <div
                    key={index}
                    className={`accordion-subitem ${isActive ? 'active' : ''}`}
                  >
                    <div className="item-header">
                      <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
                      {showIcon && item.icon && (
                        <img
                          src={item.icon.filename}
                          alt={item.title}
                          className="icon"
                        />
                      )}
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                  </div>
                ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Accordion;






