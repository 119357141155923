import React from 'react';
import Accordion from './Accordion';

const Safety = ({ title, items, options  }) => {
  return (
    <Accordion title={title} items={items} showIcon={false} isList={true}  options={options} />
  );
};

export default Safety;

