import React from 'react';

const Footer = ({ 
  text, 
  hashcode, 
  options, 
  lang, 
  cookiesAccepted, 
  onDeclineCookies, 
  onAcceptCookies,  // Funktion für Cookie-Akzeptanz
  buttonTextD, // Text für Ablehnen-Button
  buttonTextE  // Text für Akzeptieren-Button
}) => {
  return (
    <>
     <footer className="footer">
  <div className="footer-content">
    <p className="footer-left" dangerouslySetInnerHTML={{ __html: text }}></p>
  </div>
</footer>


      {!cookiesAccepted ? (
        <div className="cookie-decline-bar">
          
          <button onClick={onDeclineCookies}>{buttonTextD}</button> {/* Button zum Ablehnen */}
        </div>
      ) : (
        <div className="cookie-decline-bar">
          <button onClick={onDeclineCookies}>{buttonTextD}</button> {/* Button zum Ablehnen */}
        </div>
      )}
    </>
  );
};

export default Footer;


