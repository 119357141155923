import React from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import LanguageWrapper from './components/LanguageWrapper';
import './styles.css';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/:lang" element={<LanguageWrapper />} />
          <Route path="/" element={<LanguageRedirect />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

function LanguageRedirect() {
  return <Navigate to="/en" replace />;
}

export default App;

